<template>
    <div>
        <nav-bar title="订单详情" url="/sale"></nav-bar>
        <div class="top">
            <div class="title">
                <div class="title-text">
                    基本信息
                </div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-cell-group>
                <van-cell title="姓名" :value="info.name"></van-cell>
                <van-cell title="电话" :value="info.tel"></van-cell>
                <van-cell title="地址" :value="info.address"></van-cell>
                <van-cell title="订单号" :value="info.order_id"></van-cell>
                <van-cell title="下单时间" :value="info.create_time"></van-cell>
                <van-cell title="办卡类型" :value="info.cate.cate_name"></van-cell>
                <van-cell title="金额" :value="'￥'+info.money"></van-cell>
                <van-cell title="佣金" :value="'￥'+info.cate.extend_money"></van-cell>
                <van-cell title="状态" :value="info.state"></van-cell>
                <van-cell title="审核理由" :value="info.examine_content"></van-cell>
            </van-cell-group>
        </div>

    </div>
</template>

<script>
    import {
        sale_detail,
    } from '@/api/sale.js';
    export default {
        name: 'sale_detail',
        data() {
            return {
                info: { cate: {} ,pay_money:0,money:0},
            }
        },
        mounted() {
            this.get_data();
        },
        methods: {
            get_data() {
                sale_detail({
                    id: this.$route.query.id,
                    loading:true,
                }).then(res => {
                    if (res.code == 1) {
                        this.info = res.data;
                    }
                });
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }
</style>